import { AppBar, AppBarProps, Box, Stack, Toolbar, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AccountPopover from "../dashboard/header/AccountPopover";

type TssAppBarProps = {
    title?: ReactNode
    endSection?: ReactNode
    projectId?: string
    appBarProps?: AppBarProps
}

export default function TssAppBar({title, endSection, projectId, appBarProps}: TssAppBarProps) {
    const {t} = useTranslation()
    const additionalLinks = [{
        label: t('admin'),
        href: '/admin'
    }];
    if (projectId) {
        additionalLinks.unshift({
            label: t('projectInterviewList'),
            href: `/baseline?id=${projectId}`
        })
    }
    return (
        <AppBar {...appBarProps}>
            <Toolbar>
                {title && (
                    <>
                        {typeof title === 'string' ? (
                            <Typography variant={'h4'} >
                                {title}
                            </Typography>
                        ) : title}
                    </>
                )}
                <Box sx={{flexGrow: 1}}/>
                {endSection}
                <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
                    <AccountPopover
                        LinkComponent={Link}
                        additionalLinks={additionalLinks}/>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}