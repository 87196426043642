import {Button, Stack, TableCell, TableRow} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {ResolveDataSectionDetailProps} from "../baselineOverviewTypes";
import {BssColsToIncludeNulls, getDataField, sumOrAvgData} from "../baselineGroupHelper";
import {useState} from "react";
import {Sort} from "@mui/icons-material";


type GroupWealthAndInterviewData = {
    [k: string]: {
        [k: string]: number
    }
};


export function ResolveDataSectionDetail({
                                             groupedByWealthGroupData,
                                             dataName,
                                             showOneCard
                                         }: ResolveDataSectionDetailProps) {
    const [sortDesc, setSortDesc] = useState<boolean>(false)
    const {wealthGroups, interviews, interviewCountByWealthGroup} = useBaselineOverviewContext()
    const groupWealthAndInterviewData: GroupWealthAndInterviewData = {}
    const groupBySort: {
        [K: string]: number[]
    } = {}
    wealthGroups.forEach((wealthGroup) => {
        const groupByLocation: {
            [K: string]: number
        } = interviews.reduce((previousValue, currentValue) => {

            const filterByLocation = (groupedByWealthGroupData[wealthGroup.id]?.data || [])
                // @ts-ignore
                .filter(d => {
                    // @ts-ignore
                    if ('project_interview_base' in d) {
                        return d.project_interview_base?.project_interview?.location_id === currentValue.location_id
                        // @ts-ignore
                    } else if ('project_interview' in d) {
                        return d.project_interview?.location_id === currentValue.location_id
                    }
                })
            return {
                ...previousValue,
                [currentValue.id]: sumOrAvgData({data: filterByLocation as any, col: dataName})
            }
        }, {});
        groupBySort[wealthGroup.id] = Object.keys(groupByLocation)
            .map(key => groupByLocation[key])
            .sort((a, b) => {
                return a - b
            })
        groupWealthAndInterviewData[wealthGroup.id] = groupByLocation
    })

    return (
        <>
            {interviews.map((interview, index) => (
                <TableRow key={interview.id}>
                    {!showOneCard && wealthGroups.map(wealthGroup => (
                        <TableCell key={wealthGroup.id}/>
                    ))}
                    <TableCell align={'right'}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            {index === 0 ? (
                                <Button size={'small'}
                                        color={sortDesc ? 'primary' : 'inherit'}
                                        startIcon={<Sort/>}
                                        variant={sortDesc ? 'outlined' : undefined}
                                        onClick={() => {
                                            setSortDesc(prevState => !prevState)
                                        }}>

                                </Button>
                            ) : (
                                <span/>
                            )}
                            <span>
                            {sortDesc ? `# ${index + 1}` : interview.location.name}
                            </span>
                        </Stack>
                    </TableCell>
                    {wealthGroups.map(wealthGroup => {
                        const currentVal = sortDesc ? groupBySort[wealthGroup.id][index] : groupWealthAndInterviewData[wealthGroup.id][interview.id]
                        const interviewExistsByWealthGroupId = interviewCountByWealthGroup[wealthGroup.id].locationList[interview.location_id];
                        return (
                            <TableCell key={wealthGroup.id} sx={{bgcolor: 'primary.lighter'}} align={'center'}>
                                {currentVal
                                    ? getDataField(currentVal, dataName)
                                    : interviewExistsByWealthGroupId ? !BssColsToIncludeNulls.includes(dataName) ? 0 : '-' : '-'
                                }
                            </TableCell>
                        )
                    })}
                </TableRow>
            ))}
        </>

    )
}